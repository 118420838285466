<template>
  <base-loader v-if="loading"></base-loader>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center justify-content-between">
        <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
          {{ title }}
        </h3>
        <button class="btn btn-success btn-sm" @click="categoryForm()">
          <i class="fas fa-plus"></i>
        </button>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="categories"
      >
        <!-- Table Head -->
        <template v-slot:columns>
          <th>#</th>
          <th>{{ $t("tables.name") }}</th>
          <th>{{ $t("tables.name") }}</th>
          <th>{{ $t("tables.sub_categories_num") }}</th>
          <th class="text-right">{{ $t("tables.action") }}</th>
        </template>

        <!-- Table Body -->
        <template v-slot:default="row">
          <td class="budget">
            {{ row.item.id }}
          </td>
          <th scope="row">
            <div class="media align-items-center">
              <a href="#" class="avatar rounded-circle mr-3">
                <img :src="row.item.image" />
              </a>
              <div class="media-body">
                <span class="name mb-0 text-sm">{{ row.item.name }}</span>
              </div>
            </div>
          </th>
          <td class="budget">
            {{ row.item.local_name }}
          </td>
          <td class="budget">
            {{ row.item.subTraderTypes.length }}
          </td>
          <td class="text-right">
            <router-link
              :to="`/category/${row.item.id}`"
              class="btn btn-primary btn-sm"
              >{{ $t("tables.sub_categories") }} <i class="fas fa-eye"></i
            ></router-link>
            <button
              class="btn btn-success btn-sm"
              @click="categoryForm(row.item)"
            >
              <i class="fas fa-edit"></i>
            </button>
          </td>
        </template>
      </base-table>
    </div>

    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <!-- Pagination Component -->
      <base-pagination
        :page="page"
        :total_pages="total_pages"
        v-on:pagination-num="page = $event"
      />
    </div>
  </div>
  <!-- Category Modal -->
  <div
    v-if="CategoryModal"
    class="modal fade show d-block"
    id="CategoryModal"
    tabindex="-1"
    aria-labelledby="CategoryModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="CategoryModalLabel">
            {{ $t("form.category_form") }}
          </h5>
          <button
            type="button"
            class="btn-secondary"
            @click="CategoryModal = false"
          >
            x
          </button>
        </div>
        <div class="modal-body">
          <form>
            <!-- Errors Handleing -->
            <div class="row bg-danger py-3 mb-3" v-if="validationErrors.length">
              <div
                class="col-md-6"
                v-for="(error, index) in validationErrors"
                :key="index"
              >
                <span class="text-white mr-2 error-handling">{{ error }}</span>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6 mb-3">
                <label class="form-control-label">{{
                  $t("form.en_name")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="updatedData.name_en"
                />
              </div>
              <div class="col-lg-6 mb-3">
                <label class="form-control-label">{{
                  $t("form.ar_name")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="updatedData.name_ar"
                />
              </div>
              <div class="col-lg-6 mb-3">
                <label class="form-control-label">{{ $t("form.sort") }}</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="updatedData.sort"
                />
              </div>
              <div class="col-md-6">
                <div class="form-group has-label">
                  <label class="form-control-label">{{
                    $t("form.image")
                  }}</label>
                  <input
                    type="file"
                    class="form-control form-control-alternative"
                    v-on:change="uploadImage"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            @click="CategoryModal = false"
          >
            {{ $t("form.close") }}
          </button>
          <button
            type="button"
            class="btn btn-success"
            @click="updateCategory(categoryDetails)"
          >
            {{ $t("form.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "categories-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      renderComponent: false,
      loading: false,
      validationErrors: [],
      categories: "",
      page: 1,
      total_pages: "",
      CategoryModal: false,
      currentData: {},
      updatedData: {},
    };
  },
  created() {
    this.getCategories("admin/administration/traderTypes/indexPaginate?page=1");
  },
  watch: {
    page: function () {
      this.getCategories(
        "admin/administration/traderTypes/indexPaginate?page=" + this.page
      );
    },
  },
  methods: {
    getCategories: function ($pagination) {
      this.loading = true;
      axios
        .get($pagination)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.categories = response.data.data;
            this.page = response.data.meta.current_page;
            this.total_pages = response.data.meta.last_page;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    validation: function () {
      this.validationErrors = [];
      if (
        this.updatedData.name_en &&
        this.updatedData.name_ar &&
        this.updatedData.sort &&
        (this.updatedData.image || this.updatedData.id)
      ) {
        return true;
      } else {
        if (!this.updatedData.name_en) {
          this.validationErrors.push(
            `${this.$t("validation.en name required")}`
          );
        }
        if (!this.updatedData.name_ar) {
          this.validationErrors.push(
            `${this.$t("validation.ar name required")}`
          );
        }
        if (!this.updatedData.sort) {
          this.validationErrors.push(`${this.$t("validation.sort required")}`);
        }
        if (!this.updatedData.image && !this.updatedData.id) {
          this.validationErrors.push(`${this.$t("validation.image required")}`);
        }
        return false;
      }
    },
    // Edit Category Modal
    categoryForm(CategoryData) {
      this.updatedData = {};
      this.CategoryModal = true;
      if (CategoryData) {
        this.currentData = CategoryData;
        this.updatedData.name_en = CategoryData.name;
        this.updatedData.name_ar = CategoryData.local_name;
        this.updatedData.sort = CategoryData.sort;
        this.updatedData.id = CategoryData.id;
      }
    },
    // Create OR Update Category
    updateCategory: function () {
      if (this.validation() == false) {
        this.$swal({
          position: "center",
          icon: "error",
          title: `${this.$t("validation.faild")}`,
          showConfirmButton: false,
          timer: 1200,
        });
        return false;
      }
      this.loading = true;
      let formData = this.toFormData(this.updatedData);
      var url;
      if (this.updatedData.id) {
        url = "admin/administration/traderTypes/update";
      } else {
        url = "admin/administration/traderTypes/store";
      }
      axios
        .post(url, formData)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            if (response.data.status == 0) {
              this.$swal({
                position: "center",
                icon: "error",
                title: response.data.message,
                showConfirmButton: false,
              });
            } else {
              if (this.updatedData.id) {
                this.categories.filter((item) => {
                  if (this.updatedData.id === item.id) {
                    item.name = this.updatedData.name_en;
                    item.local_name = this.updatedData.name_ar;
                    item.sort = this.updatedData.sort;
                  }
                });
              }
              this.updatedData = {};
              this.CategoryModal = false;
              this.$swal({
                position: "center",
                icon: "success",
                title: `${this.$t("validation.success")}`,
                text: response.data.message,
                showConfirmButton: false,
                timer: 1200,
              });
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Get Image to Add
    uploadImage(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.updatedData.image = files[0];
    },
    // Form Fields
    toFormData: function () {
      var fd = new FormData();
      if (this.updatedData["image"]) {
        fd.append("image", this.updatedData["image"]);
      }
      if (this.updatedData["id"]) {
        fd.append("_method", "put");
        fd.append("id", this.updatedData["id"]);
      }
      fd.append("ar[name]", this.updatedData["name_ar"]);
      fd.append("en[name]", this.updatedData["name_en"]);
      fd.append("sort", this.updatedData["sort"]);
      return fd;
    },
  },
};
</script>
